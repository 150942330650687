body {
    background-color: black;
}

h2 {
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

h2,
.tn-elem[data-elem-id="1584189117198"] .tn-atom,
.tn-elem[data-elem-id="1584207766445"] .tn-atom,
.tn-elem[data-elem-id="1584207766445"] .tn-atom,
.tn-elem[data-elem-id="1584207766445"] .tn-atom {
    background: linear-gradient( 45deg, #fa4b4b 0%, #ffc864 25%, #fa4b4b 50%, #32b4e6 75%, #fa4b4b 100%);
    background-size: 210% 210%;
    background-attachment: fixed;
    animation: gradient 18s ease infinite;
    /*will-change: unset !important;*/
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}