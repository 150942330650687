[data-elem-id="1585459646321"] {
    pointer-events: none !important;
    will-change: unset !important;
}

#rec170428222 {
    display: none;
}

#rec169965347 {
    display: none;
}

.Product_CRM {
    display: none;
    z-index: 0;
}

.Product_App {
    display: none;
    z-index: 1;
}

.Product_Design {
    display: none;
    z-index: 2;
}
